@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

:root {
	--title: #2b2b2b;
	--title-rgb: 43, 43, 43;

	--text: #55524b;
	--text-2: #d6d5d1;

	--body: #ffffff;
	--section: #f7fbff;

	--dark: #55524b;

	--white: #ffffff;

	--base: #e6d8a8;
	--base-2: #9d8140;
	--base-3: #ffc80f;

	--gradient: linear-gradient(
		85.2deg,
		var(--base) -13.39%,
		var(--base-2) 125.15%
	);
	--gradient-2: linear-gradient(
		85.2deg,
		var(--base-2) -13.39%,
		var(--base) 125.15%
	);

	--body-fonts: "Poppins", sans-serif;
	--heading-fonts: "Poppins", sans-serif;

	--border: rgba(208, 220, 231, 0.15);
}

.accordion {
	--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%239d8140%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
body {
	background: var(--body);
	font-family: var(--body-fonts);
	color: var(--text);
	font-size: 15px;
	line-height: 22px;
	font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-family: var(--heading-fonts);
	font-weight: 700;
}

h1 {
	font-size: 42px;
}
h2 {
	font-size: 40px;
	font-weight: 600;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24px;
	}
	h4 {
		font-size: 22px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.font-light {
	font-weight: 300;
}
.font-regular {
	font-weight: 400;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600;
}
.font-bold {
	font-weight: 700;
}
.font-extra-bold {
	font-weight: 800;
}
.font-black {
	font-weight: 900;
}
.container {
	position: relative;
	z-index: 1;
}
.bg-section {
	background: var(--section) !important;
}
.bg-body {
	background: var(--body) !important;
}
.bg--gradient {
	background: var(--gradient) !important;
}
.bg--title {
	background: var(--title) !important;
}
.text--base {
	color: var(--base) !important;
}

.cmn-btn {
	color: var(--title);
	padding: 18px 22px;
	display: flex;
	gap: 6px;
	align-items: center;
	display: inline-block;
	text-transform: uppercase;
	border-radius: 5px;
	border: none;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	outline: none;
	box-shadow: none;
	transition: all ease 0.3s;
	text-align: center;
	position: relative;
	min-width: 200px;
	background: transparent;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	border-radius: 999px;
	background: var(--white);
	@include breakpoint(max-xxl) {
		padding: 13px 22px;
		min-width: 180px;
	}
	* {
		position: relative;
		z-index: 1;
	}
	&::before,
	&::after {
		content: "";
		position: absolute;
		inset: 0;
		transition: all ease 0.3s;
		border-radius: 999px;
	}
	&::after {
		background: var(--gradient);
	}
	&::before {
		opacity: 0;
		background: var(--gradient-2);
	}
	&:hover {
		&::after {
			opacity: 0;
		}
		&::before {
			opacity: 1;
		}
		color: var(--title);
	}
	@include breakpoint(max-sm) {
		padding: 10px 18px;
		font-weight: 600;
	}
}
//Header Top
header {
	position: relative;
	z-index: 999;
}
.header-top {
	font-size: 12px;
	line-height: 20px;
	padding: 11.5px 0;
}
.header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px 0;
	.logo {
		display: block;
		max-width: 86px;
		img {
			width: 100%;
		}
	}
	@include breakpoint(max-xxl) {
		padding: 20px 0;
	}
	@include breakpoint(max-lg) {
		padding: 10px 0;
		.logo {
			max-width: 70px;
		}
	}
}
.header-menu {
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	.menu {
		display: flex;
		align-items: center;
		column-gap: 20px;
		margin-inline: auto;
		li {
			a {
				display: inline-block;
				text-transform: uppercase;
				color: var(--white);
				font-weight: 400;
				font-size: 18px;
				line-height: 19px;
				&.active {
					color: var(--base);
					// font-weight: 600;
				}
				&:not(.cmn-btn) {
					padding: 10px;
				}
			}
		}

		@include breakpoint(max-xxl) {
			column-gap: 10px;
			li {
				a {
					font-size: 16px;
				}
			}
		}
	}
	@include breakpoint(max-lg) {
		width: 100%;
		height: 100vh;
		left: 0;
		top: 0;
		position: fixed;
		background: var(--title);
		z-index: 999;
		justify-content: center;
		transition: all ease 0.4s;
		overflow-y: auto;
		padding-top: 90px;
		row-gap: 20px;
		&:not(.active) {
			opacity: 0;
			visibility: hidden;
			left: 0;
			transform-origin: left;
			transform: translateX(-50px);
		}
		.menu {
			flex-direction: column;
			gap: 10px;
			justify-content: center;
			li {
				a {
					&:not(.cmn-btn) {
						padding: 10px;
					}
				}
			}
		}
		// gap: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		.right-buttons {
			justify-content: center;
		}
	}
}
.right-buttons {
	display: flex;
	flex-wrap: wrap;
	column-gap: 20px;
	align-items: center;
	@include breakpoint(xxl) {
		column-gap: 50px;
	}
	@include breakpoint(max-xxl) {
		column-gap: 10px;
		li {
			a {
				font-size: 16px;
			}
		}
	}
}
.header-icon {
	@include breakpoint(max-xxl) {
		svg {
			width: 50px !important;
		}
	}
	transition: all ease 0.3s;
	* {
		transition: all ease 0.3s;
	}
	&:hover {
		--base-2: #e6d8a8;
		--base: #9d8140;
	}
}

.footer-top {
	--text: #ffffff;
	color: var(--text);
	padding: 43px 0;
}
.footer-icons {
	display: flex;
	flex-wrap: wrap;
	gap: 9px;
	a {
		display: block;
		svg {
			width: 34px !important;
		}
	}
	.header-icon {
		svg {
			width: 34px !important;
		}
		@include breakpoint(max-xxl) {
			svg {
				width: 34px !important;
			}
		}
	}
}
.footer-widget {
	.widget-title {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 3.2px;
		text-transform: uppercase;
		color: var(--base);
		margin-bottom: 18px;
	}
	p {
		font-size: 15px;
		line-height: 22px;
	}
	ul {
		li {
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			letter-spacing: 0.42px;
		}
	}
}
.footer-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: 20px;
	row-gap: 40px;
	@include breakpoint(max-lg) {
		column-gap: 20px;
	}
	.footer-widget {
		display: block;
		max-width: 200px;
		.logo {
			display: block;
			margin-bottom: 14px;
			img {
				height: 83px;
				max-width: 100%;
			}
		}
		&.widget-link {
			ul {
				display: flex;
				flex-direction: column;
				gap: 9px;
				li {
					a {
						color: var(--white);
						&:hover {
							color: var(--base);
							font-weight: 700;
						}
					}
				}
			}
		}
		&.widget-about {
			max-width: 312px;
			p {
				margin-bottom: 26px;
				text-align: justify;
			}
		}
		&.widget-contact {
			max-width: 400px;
			ul {
				display: flex;
				flex-direction: column;
				gap: 13px;
				margin-bottom: 57px;
			}
			@include breakpoint(max-xxl) {
				max-width: 350px;
			}
		}
		@include breakpoint(max-xl) {
			@include breakpoint(lg) {
				max-width: calc(25% - 15px) !important;
				width: calc(25% - 15px) !important;
				p {
					font-size: 14px;
					font-weight: 400;
				}
				ul {
					li {
						font-size: 14px;
						font-weight: 400;
						letter-spacing: 0;
					}
				}
			}
		}
		@include breakpoint(max-lg) {
			max-width: calc(50% - 20px) !important;
			width: calc(50% - 20px) !important;
		}
		@include breakpoint(max-sm) {
			width: 230px !important;
			max-width: 100% !important;
			flex-grow: 1;
			p {
				font-size: 14px;
				font-weight: 400;
			}
			ul {
				li {
					font-size: 13px;
					font-weight: 400;
					letter-spacing: 0;
				}
			}
		}
	}
	* {
		white-space: initial;
		/* word-break: break-all; */
	}
}
//Banner Section
.banner-section {
	position: relative;
	padding: 150px 0;
	&::before {
		position: absolute;
		content: "";
		inset: 0;
		background: rgba(#000, 0.4);
	}
	@include breakpoint(max-xxl) {
		padding: 120px 0;
	}
	@include breakpoint(max-xl) {
		padding: 100px 0;
	}
	@include breakpoint(max-md) {
		padding: 50px 0;
	}
}
.banner-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: 30px;
	row-gap: 50px;
	@include breakpoint(max-lg) {
		justify-content: center;
	}
	.banner-form {
		max-width: 390px;
	}
	.banner-content {
		width: 0;
		flex-grow: 1;
		max-width: 626px;
		margin-top: 15px;
		.line {
			width: 43px;
			height: 7px;
			background: var(--base);
			border-radius: 4px;
			display: block;
			margin-bottom: 10px;
		}
		.title {
			color: var(--white);
			font-weight: 600;
			font-size: 60px;
			line-height: 90px;
			margin-bottom: 14px;
		}
		p {
			font-size: 18px;
			line-height: 27px;
			color: var(--white);
			margin-bottom: 43px;
		}
		@include breakpoint(max-xl) {
			.title {
				font-size: 48px;
				line-height: 1.5;
			}
		}
		@include breakpoint(max-lg) {
			width: 100%;
		}
		@include breakpoint(max-md) {
			.title {
				font-size: 42px;
			}
			p {
				margin-bottom: 30px;
			}
		}
		@include breakpoint(max-md) {
			.title {
				font-size: 36px;
			}
		}
	}
}

.banner-form {
	padding: 43px 34px 30px;
	h5 {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 14px;
		text-transform: uppercase;
	}
	p {
		margin-bottom: 38px;
		color: var(--white);
	}
	border-radius: 20px;
	.form-label {
		display: block;
		margin-bottom: 0;
		padding-bottom: 3px;
		color: var(--white);
	}
	.form-group {
		margin-bottom: 20px;
	}
}
.form-control-2 {
	background: transparent;
	border: none;
	border-bottom: 1px solid var(--white);
	width: 100%;
	height: 30px;
	color: var(--white);
	&:focus {
		box-shadow: none;
		outline: none;
		color: var(--white);
		&::placeholder {
			color: var(--section);
		}
	}
}
.form-control {
	background-color: var(--white);
	color: var(--title);
	height: 40px;
	&:focus,
	&:active {
		border-color: var(--base);
		box-shadow: none;
		background-color: var(--white);
		color: var(--title);
	}
}
textarea.form-control,
textarea.theme-input-style {
	block-size: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
	-webkit-text-fill-color: var(--title) !important;
}

.form-control-2 {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px var(--title) inset !important;
		-webkit-text-fill-color: var(--white) !important;
	}
}
.section-gap {
	padding: 71px 0;
}
.section-title {
	.line {
		width: 43px;
		height: 7px;
		background: var(--base);
		border-radius: 4px;
		display: block;
		margin-bottom: 10px;
	}
	.title {
		&:not(:first-child) {
			margin-top: 16px;
		}
	}
	.text {
		margin-top: 11px;
		font-weight: 700;
		font-size: 17px;
		line-height: 22px;
	}
	margin-bottom: 29px;
}
.mt-29 {
	margin-top: 29px;
}
.mt-37 {
	margin-top: 37px;
}
@include breakpoint(max-sm) {
	.mt-37 {
		margin-top: 28px;
	}
	.mt-29 {
		margin-top: 22px;
	}
}
.virtual-brands-section {
	background: linear-gradient(180deg, #d6d5d1 0%, #55524b 100%);
	.container {
		@include breakpoint(xl) {
			max-width: 1060px;
		}
		.section-title {
			margin-bottom: 25px;
		}
		.header-text {
			margin-bottom: 45px;
			@include breakpoint(max-md) {
				margin-bottom: 30px;
			}
		}
	}
}
.brands-item {
	text-align: center;
	position: relative;
	border-radius: 6.69px;
	overflow: hidden;
	img {
		width: 100%;
		transition: all ease 0.3s;
	}
	.content {
		position: absolute;
		bottom: 40px;
		left: 0;
		width: 100%;
		h5 {
			font-size: 18px;
			color: var(--white);
		}
		.cmn-btn {
			margin-top: 18.5px;
		}
	}
	@include breakpoint(max-sm) {
		max-width: 350px;
		margin: 0 auto;
	}
	position: relative;
	&::before {
		transition: all ease 0.3s;
		position: absolute;
		content: "";
		inset: 0;
		background: linear-gradient(
			180deg,
			rgba(87, 87, 87, 0) 29.79%,
			#171717 100%
		);
		opacity: 1;
	}
	&:not(:hover) {
		&::before {
			opacity: 0;
		}
	}
}
.how-section {
	position: relative;
	overflow: hidden;
	.right-shape {
		position: absolute;
		left: calc(50% + 550px);
		top: 0;
	}
	.left-shape {
		position: absolute;
		right: calc(50% + 600px);
		bottom: 0;
	}
}
.why-partner-section {
	padding: 59px 0;
	.container {
		position: relative;
		z-index: 2;
		@include breakpoint(lg) {
			max-width: 1436px;
		}
	}
	&::before {
		content: "";
		inset: 0;
		position: absolute;
		background: rgba(29, 28, 26, 0.84);
	}
	position: relative;
}
.partner-content {
	padding: 34px 26.5px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	.section-title {
		.text {
			font-size: 15px;
		}
	}
	@include breakpoint(max-md) {
		padding: 30px 20px;
	}
}
.checks-list {
	display: flex;
	flex-wrap: wrap;
	margin-top: 32px;
	gap: 4px;
	li {
		width: calc(50% - 2px);
		@include breakpoint(max-md) {
			width: 100%;
		}
	}
}
.max-xl-100 {
	@include breakpoint(max-xl) {
		width: 100%;
	}
}
.book-now-section {
	padding: 40px 0;
}
.book-form {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	padding: 32px 40px 40px;
	max-width: 600px;
	.form-control {
		&:where(input) {
			height: 40px;
		}
		&:where(textarea) {
			height: 80px;
		}
	}
	@include breakpoint(max-md) {
		padding: 30px;
	}
	@include breakpoint(max-sm) {
		padding: 30px 20px;
	}
}
.shape {
	position: absolute;
	top: 0;
	right: calc(50% + 355px);
	opacity: 0.5;
	&.odd {
		left: calc(50% + 355px);
		right: 0;
	}
}
.brands-inner-section {
	.section-gap {
		padding: 55px 0;
		@include breakpoint(max-lg) {
			padding: 0;
			&:not(:last-child) {
				padding-bottom: 40px;
			}
		}
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
}
.page-header {
	position: relative;
	&::before {
		inset: 0;
		content: "";
		position: absolute;
		background: rgba(#000000, 0.4);
	}
	text-align: center;
	padding: 80px 0;
	@include breakpoint(lg) {
		padding: 93px 0;
		.title {
			font-size: 60px;
			font-weight: 600;
			margin: 0;
		}
	}
	.title {
		color: var(--white);
	}
	.container {
		position: relative;
		z-index: 1;
	}
}
.contact-section {
	padding: 91px 0 79px;
	background: rgba(#f9f9f9, 0.5);
	@include breakpoint(max-lg) {
		padding: 70px 0;
	}
}
.contact-card {
	background: #ffffff;
	box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	height: 100%;
	padding: 25px 15px 28px;
	text-align: center;
	.icon {
		margin-bottom: 16px;
	}
	a {
		color: var(--text);
	}
}
.about-feature-section {
	background: var(--gradient);
	padding: 40px 0;
	.container {
		@include breakpoint(xl) {
			max-width: 1272px;
		}
	}
}
.feature-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// min-height: 200px;
	.icon {
		margin-bottom: 12.77px;
	}
	text-align: center;
	.title {
		font-size: 16px;
		font-weight: 600;
	}
	height: 100%;
	padding: 30px 10px;
	background: #ffffff;
	box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	@include breakpoint(max-sm) {
		padding: 20px 10px;
		.icon {
			height: 50px;
			svg {
				width: 40px;
				height: 40px;
			}
		}
		.title {
			font-size: 14px;
		}
	}
}
.story-content {
	padding: 34px 32px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	p {
		max-width: 720px;
		&:not(:last-child) {
			margin-bottom: 23px;
		}
	}
	.section-title {
		.text {
			font-size: 15px;
		}
	}
	@include breakpoint(max-md) {
		padding: 30px 20px;
	}
}
.story-section {
	overflow: hidden;
	position: relative;
	.points {
		top: 55px;
		position: absolute;
	}
	@include breakpoint(xl) {
		.container {
			max-width: 1432px;
		}
	}
}
.story-img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	object-fit: cover;
	@include breakpoint(max-lg) {
		max-height: 450px;
	}
}
.story-img-thumb {
	max-width: 520px;
	@include breakpoint(max-lg) {
		max-width: 100%;
	}
}
.__accordion {
	.accordion-item {
		margin: 0 !important;
		border: none;
		border-bottom: 2px solid #caaa60;
		// .accordion-button {
		// 	border-bottom: 2px solid transparent;
		// 	&:not(.collapsed) {
		// 		border-color: #caaa60;
		// 	}
		// }
		.accordion-header,
		.accordion-button {
			box-shadow: none !important;
			outline: none !important;
			border-radius: 0 !important;
			background: transparent;
			font-size: 20px;
			font-weight: 600;
			color: var(--title);
			@include breakpoint(max-lg) {
				font-size: 18px;
				font-weight: 500;
			}
		}
		border-radius: 0 !important;
		.accordion-button {
			padding: 30px 0 !important;
			@include breakpoint(max-md) {
				padding: 20px 0 !important;
			}
		}
		&:first-child {
			border-top: 2px solid #caaa60;
		}
		.accordion-body {
			padding: 0 0 30px;
			p {
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.faqs-section {
	.container {
		@include breakpoint(xl) {
			max-width: 1344px;
		}
	}
}
.subscribe-section {
	padding: 67px 0;
	position: relative;
	&::before {
		inset: 0;
		content: "";
		position: absolute;
		background: rgba(#000000, 0.4);
	}
}
.subscribe-content {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	max-width: 751px;
	margin: 0 auto;
	padding: 36px 20px;
	@include breakpoint(lg) {
		padding-bottom: 56px;
	}
	.content {
		max-width: 518px;
		margin: 0 auto;
	}
}
.blog-card {
	.content {
		@include breakpoint(lg) {
			max-width: 580px;
		}
	}
	&:not(:last-child) {
		margin-bottom: 70px;
	}
	&:nth-child(even) {
		.content {
			@include breakpoint(lg) {
				margin-left: auto;
			}
		}
	}
}
.hand-img {
	@include breakpoint(lg) {
		position: absolute;
		left: calc(50% + 120px);
	}
	@include breakpoint(xl) {
		left: calc(50% + 250px);
	}
	@media (width > 1600px) {
		left: calc(50% + 360px);
	}
	@include breakpoint(max-lg) {
		max-width: 360px;
	}
}
.how-card {
	background: #ffffff;
	border-radius: 10px;
	padding: 46px 20px 31px;
	height: 100%;
	position: relative;
	.serial {
		position: absolute;
		left: 20px;
		top: 20px;
		font-weight: 700;
		font-size: 30px;
		line-height: 36px;
	}
	.img {
		height: 217px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}
	h6 {
		font-weight: 600;
		font-size: 15px;
		line-height: 22px;
	}
	ul {
		font-weight: 600;
		font-size: 15px;
		line-height: 22px;
		max-width: 216px;
		margin: 0 auto;
		text-align: left;
		padding-left: 16px;
		li {
			list-style: disc;
		}
	}
	text-align: center;
	@include breakpoint(max-lg) {
		.img {
			height: 150px;
		}
	}
}
.hidden-charge-content {
	font-size: 18px;
	font-weight: 500;
	// font-family: var(--heading);
	line-height: 1.6;
	h6 {
		line-height: 1.5;
	}
	li {
		font-size: 18px;
		font-weight: 500;
		strong {
			font-weight: 700;
		}
	}
}
ul.hidden-charge-content {
	li {
		list-style: decimal;
	}
	strong {
		font-weight: 700;
	}
}
.point_shape {
	position: absolute;
	bottom: 30px;
	left: 30px;
}
.delivery-feature {
	position: relative;
	overflow: hidden;
	transform: translateY(-39px);
	// transform: translateY(-69px);
	margin-bottom: -39px;
	// margin-bottom: -69px;
	padding-bottom: 69px;
	.container {
		position: relative;
		z-index: 9;
	}
}
.mobile-full {
	@include breakpoint(max-lg) {
		// width: 100%;
		img {
			width: 100%;
		}
	}
}
.mobile-full-2 {
	width: 200px;
	transform: translateX(50px) rotate(8deg) skew(-10deg, -3deg);
	@include breakpoint(max-lg) {
		transform: rotate(8deg) skew(-10deg, -3deg);
	}
	@include breakpoint(max-sm) {
		transform: rotate(8deg) skew(-10deg, -3deg);
		max-width: 60%;
	}
}
.btn-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	row-gap: 20px;
	column-gap: 37px;
	@include breakpoint(max-md) {
		column-gap: 20px;
		a {
			&:not(.cmn-btn) {
				max-width: calc(50% - 10px);
			}
		}
	}
	&:not(.cmn-btn) {
		img {
			max-width: 100%;
		}
	}
}
.team-modal {
	.modal-title {
		font-weight: 600;
		font-size: 32px;
		line-height: 48px;
		@include breakpoint(max-sm) {
			font-size: 24px;
		}
	}
	.btn-close {
		box-shadow: none;
	}
	.modal-dialog {
		max-width: 1236px;
		@include breakpoint(max-lg) {
			margin-block: 0;
		}
	}
	.modal-content {
		background: var(--gradient);
		@include breakpoint(max-lg) {
			border-radius: 0;
		}
	}
	.modal-body {
		padding-top: 0;
		padding-inline: 30px;
		padding-bottom: 30px;
		@include breakpoint(max-sm) {
			padding-bottom: 15px;
			padding-inline: 15px;
		}
	}
}
.team-card {
	background: #ffffff;
	border-radius: 8px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	> * {
		width: 100%;
	}
	.author-img {
		border-radius: 50%;
		width: 190px;
		aspect-ratio: 1;
		object-fit: cover;
		margin-bottom: 31px;
	}
	.name {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 22px;
		line-height: 33px;
	}
	.designation {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
	}
	height: 100%;
	padding: 45px 35px 35px;
	ul {
		text-align: left;
		margin-top: 22px;
		margin-bottom: 50px;
		font-weight: 600;
		font-size: 15px;
		line-height: 22px;
		color: #000000;
	}
	.cmn-btn {
		margin-top: auto;
		width: unset;
		padding: 6.5px 24px;
		min-width: 120px;
		font-weight: 700;
		font-size: 12px;
	}
	@include breakpoint(max-md) {
		padding: 30px 20px;
		.author-img {
			width: 100px;
			margin-bottom: 10px;
		}
		.name {
			font-size: 18px;
			margin-bottom: 2px;
		}
		.designation {
			font-size: 16px;
		}
		ul {
			font-size: 14px;
			margin-bottom: 25px;
		}
	}
}
