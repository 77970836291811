@import "../../App.scss"; // Ensure it follows global styles

.just-chicken-wings-page {
  font-family: var(--body-fonts);
  color: var(--text);
  text-align: center;

  /* HERO SECTION */
  .hero-section {
    background: var(--section);
    padding: 70px 20px;

    .brand-logo {
      width: 420px;
      margin-bottom: 20px;
    }

    .video-container {
        margin: 20px auto;
        max-width: 800px; // Adjust the width of the video
        width: 100%;
    }
    
    .hero-video {
        width: 100%;
        border-radius: 10px; // Rounded corners
    }
    

    .featured-image {
      width: 100%;
      max-width: 600px;
      border-radius: 10px;
      margin-top: 20px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  /* BUSINESS VALUE PROPOSITION */
.business-section {
    background: linear-gradient(to bottom, #D6B678 0%, #000000 100%);
    color: var(--white);
    padding: 20px 20px;
  
    .business-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      max-width: 1200px;
      margin: auto;
    }
  
    .phone-mockup {
      width: 500px;
      max-width: 100%;
      border-radius: 10px;
        margin-top: 10px; // 👈 Pushes the phone further down
    }
  
    .business-text {
      flex: 1;
      text-align: left;
  
      h2 {
        font-size: 2rem;
        margin-bottom: 30px;
      }
  
      p {
        font-size: 1.2rem;
        max-width: 600px;
      }
    }
  
    /* Responsive Design */
    @include breakpoint(max-md) {
      .business-content {
        flex-direction: column;
        text-align: center;
      }
  
      .phone-mockup {
        width: 100%;
        max-width: 300px;
      }
  
      .business-text {
        text-align: center;
      }
    }
  }
  

/* KEY METRICS SECTION */
.metrics-section {
    background: var(--body);
    padding: 70px 20px;

    .container {
        display: flex;
        justify-content: center; // Centers the metrics
        gap: 50px; // Adds spacing between items
        flex-wrap: wrap; // Ensures responsiveness
    }

    .metrics {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 200px;
    }

    .metrics img {
        width: 120px; // Ensures all images have the same width
        height: 120px; // Ensures uniform height
        border-radius: 50%; // Makes images rounded
        object-fit: cover; // Ensures the image fills the circle without distortion
        border: 3px solid var(--base-2); // Optional: Adds a subtle border for a clean look
        margin-bottom: 10px;
    }

    .metrics p {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--title);
    }
}

  /* TESTIMONIAL SECTION */
  .testimonial-section {
    background: #393939;
    color: var(--white);
    padding: 70px 20px;

    blockquote {
      font-size: 1.5rem;
      font-style: italic;
      max-width: 600px;
      margin: auto;
    }

    cite {
      display: block;
      margin-top: 10px;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  /* MENU SECTION */
  .menu-section {
    padding: 70px 20px;

    h2 {
      font-size: 2rem;
      margin-bottom: 30px;
    }

    .menu-items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      .menu-card {
        background: var(--white);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        text-align: center;
        width: 250px;

        img {
          width: 100%;
          border-radius: 10px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 1.5rem;
          color: var(--title);
        }

        p {
          font-size: 1rem;
          color: var(--text);
        }
      }
    }
  }

  /* DELIVERY PLATFORMS */
  .delivery-section {
    background: linear-gradient(to bottom, #00a99d 0%, #000000 100%);
    color: var(--white);
    padding: 20px 20px;

    display: flex;
    align-items: center; // Centre verticalement
    justify-content: center; // Centre horizontalement ✅

    .container {
        display: flex; // Active Flexbox
        align-items: center; // Centre verticalement ✅
        justify-content: center; // Centre horizontalement ✅
        gap: 40px; // Ajoute un espace entre le texte et l’image
        max-width: 1200px; // Assure un bon alignement global
        margin: auto; // Centre l'ensemble du contenu ✅
    }

    .text-content {
        flex: 1;
        max-width: 50%;
        text-align: left;
        padding-right: 20px;
    }

    .delivery-logos {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
            width: 250px;
            max-width:100%;
        }
    }

    @include breakpoint(max-md) {
        flex-direction: column;
        text-align: center;

        .text-content {
            max-width: 100%;
            text-align: center;
            padding-right: 0;
        }

        .delivery-logos {
            justify-content: center;
            margin-top: 20px;
        }
    }
}

  
  
  

  /* CALL-TO-ACTION (CTA) */
 /* CALL-TO-ACTION (CTA) */
.cta-section {
    background: var(--gradient);
    color: var(--white);
    padding: 20px 20px;
    text-align: center;

    h2 {
        font-size: 2rem;
    }

    p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    .cta-image {
        width: 250px; // Reduced image size
        max-width: 100%; // Ensure responsiveness
        margin: 20px auto; // Center image
        display: block; // Prevent inline issues
        border-radius: 20px; // Rounded corners ✅
    }

    .cmn-btn {
        background: var(--white);
        color: var(--title);
        padding: 15px 30px;
        font-size: 1.2rem;
        font-weight: bold;
        border-radius: 999px;
        display: inline-block;
        transition: 0.3s ease-in-out;

        &:hover {
            background: var(--base);
            color: var(--title);
        }
    }
}



  /* OTHER BRANDS SECTION */
  .other-brands-section {
    padding: 70px 20px;

    h2 {
      font-size: 2rem;
      margin-bottom: 30px;
    }

    .other-brands {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      .brand-card {
        background: var(--white);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        text-align: center;
        width: 250px;

        img {
          width: 100%;
          border-radius: 10px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 1.0rem;
          color: var(--title);
        }

        .cmn-btn {
          background: var(--base-2);
          color: var(--white);
          padding: 10px 20px;
          font-size: 1rem;
          font-weight: bold;
          border-radius: 5px;
          display: inline-block;
          transition: 0.3s ease-in-out;

          &:hover {
            background: var(--base);
            color: var(--title);
          }
        }
      }
    }
  }

  /* RESPONSIVE STYLES */
  @include breakpoint(max-md) {
    .hero-section,
    .business-section,
    .metrics-section,
    .testimonial-section,
    .menu-section,
    .delivery-section,
    .cta-section,
    .other-brands-section {
      padding: 50px 15px;
    }

    .menu-items,
    .other-brands {
      flex-direction: column;
      align-items: center;
    }
  }

  @include breakpoint(max-sm) {
    h2 {
      font-size: 24px;
    }

    .cmn-btn {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
}

  